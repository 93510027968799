import { RequireAuth, useAuthentication } from '@iad-os/react-ghost-auth';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppStore } from '../libs/contexts/AppStore';

const ProtectedRouteLayout: React.FC = () => {
  const { state } = useAppStore();
  const { isAuthenticated } = useAuthentication();

  return (
    <RequireAuth authRequired={true}>
      {isAuthenticated() && !state.boardingCompleted ? (
        <Navigate to={'/onboarding'} />
      ) : (
        <Outlet />
      )}
    </RequireAuth>
  );
};

export default ProtectedRouteLayout;
