import React, { useEffect } from 'react';
import {
  RouteObject,
  useNavigate,
  useParams,
  useRoutes,
} from 'react-router-dom';
import SimpleRouteLayout from '../layouts/SimpleRouteLayout';
import { ErrorBoundary, PageNotFountBoundary } from './Routes';
import { COMMON_ROUTE, protectedNavigate, publicNavigate } from './routesUtils';
import { getLoggedIn } from '@iad-os/react-ghost-auth';

const routes: RouteObject = {
  element: <SimpleRouteLayout />,
  children: [
    {
      path: `${COMMON_ROUTE.TICKET_CHECKOUT}/:voucher`,
      element: <CommonCheckOut />,
    },
    {
      path: '/*',
      element: <PageNotFountBoundary />,
    },
  ],
  errorElement: <ErrorBoundary />,
};

const CommonRouters: React.FC = () => {
  return useRoutes([routes]);
};

function CommonCheckOut() {
  const { voucher } = useParams<{ voucher: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (getLoggedIn()) {
      navigate(protectedNavigate('TICKET_CHECKOUT') + `/${voucher}`);
    } else {
      navigate(publicNavigate('TICKET_CHECKOUT') + `/${voucher}`);
    }
    // eslint-disable-next-line
  }, []);

  return <></>;
}

export default CommonRouters;
