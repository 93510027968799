import { useAuthentication } from '@iad-os/react-ghost-auth';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import useWellKnown from '../hooks/useWellKnown';
import { useAppStore } from '../libs/contexts/AppStore';
import { protectedNavigate } from '../routes/routesUtils';
import { useNavigate } from 'react-router-dom';

const VoucherSuccess = () => {
  const { dispatch } = useAppStore();
  const { wellKnown } = useWellKnown();
  const { isAuthenticated } = useAuthentication();
  const auth = useMemo(isAuthenticated, [isAuthenticated]);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: 'update',
      field: {
        key: 'title',
        value: { text: '', imageUrl: wellKnown?.metadata.images.tab4 },
      },
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid textAlign="center">
        {auth && (
          <>
            <Typography variant="h4" sx={{ pt: 6 }}>
              La tua richiesta è stata creata con successo!
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate(protectedNavigate('TICKETS'))}
            >
              VAI ALLA LISTA TICKET
            </Button>
          </>
        )}
        {!auth && (
          <Typography variant="h4" color={'red'} sx={{ pt: 6 }}>
            PER COMPLETARE LA RICHIESTA, CONTROLLA LA TUA E-MAIL E CLICCA SUL
            LINK
          </Typography>
        )}
      </Grid>
    </div>
  );
};

export default VoucherSuccess;
