import isEmpty from 'lodash.isempty';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

function useDeviceDetect() {
  const isDesktopOrLaptop = useMemo(
    () =>
      isEmpty(
        navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigator.userAgent]
  );
  const isTabletPortraint = useMediaQuery({
    minWidth: 761,
    maxWidth: 1224,
    orientation: 'portrait',
  });
  const isTabletLandscape = useMediaQuery({
    minHeight: 761,
    maxHeight: 1224,
    orientation: 'landscape',
  });
  const isPhonePortraint = useMediaQuery({
    maxWidth: 760,
    orientation: 'portrait',
  });
  const isPhoneLandscape = useMediaQuery({
    maxHeight: 760,
    orientation: 'landscape',
  });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isTablet = useMemo(
    () => !isDesktopOrLaptop && (isTabletLandscape || isTabletPortraint),
    [isTabletLandscape, isTabletPortraint, isDesktopOrLaptop]
  );
  const isPhone = useMemo(
    () => !isDesktopOrLaptop && (isPhoneLandscape || isPhonePortraint),
    [isPhoneLandscape, isPhonePortraint, isDesktopOrLaptop]
  );

  const device = useMemo<'des' | 'mob' | 'tab'>(() => {
    if (isTablet) {
      return 'tab';
    }
    if (isPhone) {
      return 'mob';
    }
    return 'des';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isTabletLandscape,
    isTabletPortraint,
    isDesktopOrLaptop,
    isPhoneLandscape,
    isPhonePortraint,
  ]);

  return {
    isDesktopOrLaptop,
    isPortrait,
    isTablet,
    isPhone,
    device,
  };
}

export default useDeviceDetect;
