import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { Images } from '../containers/ticketChechoutContainer';
export type WellKnown = {
  domain: string;
  metadata: Metadata;
};

type Issuer = {
  name?: string;
  authorization_endpoint: string;
  token_endpoint: string;
  client_id: string;
  requested_scopes: string;
  redirect_uri: string;
  end_session_endpoint: string;
  redirect_logout_uri: string;
  pkce: boolean;
};
export type Icons = {
  wait: string;
  'request-wait': string;
  download: string;
  error: string;
};
type Metadata = {
  name: string;
  endpoint_api: string;
  issuer: Issuer;
  active_debug: boolean;
  privacyUrl: string;
  rolesUrl: string;
  accessRolesUrl: string;
  conductRolesUrl: string;
  images: Images;
  icons: Icons;
  policyUrl: string;
  cookieUrl: string;
  htmlBlocks?: Record<string, string>;
};

type IconShape = Record<keyof Metadata['icons'], yup.AnySchema>;
type ImageShape = Record<keyof Metadata['images'], yup.AnySchema>;
type IssuerShape = {
  [key in keyof WellKnown['metadata']['issuer']]: yup.AnySchema;
};
type ImagesKeys = Array<keyof Metadata['images']>;
const URL =
  /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const urlYup = yup.string().matches(URL, 'Enter a valid url');
const wellknownSchema = yup
  .object()
  .shape<{ [key in keyof WellKnown]: yup.AnySchema }>({
    domain: urlYup.required(),
    metadata: yup
      .object()
      .shape<{ [key in keyof WellKnown['metadata']]: yup.AnySchema }>({
        name: yup.string().required(),
        active_debug: yup.boolean(),
        endpoint_api: urlYup.required(),
        privacyUrl: urlYup.required(),
        accessRolesUrl: urlYup.required(),
        conductRolesUrl: urlYup.required(),
        rolesUrl: urlYup.required(),
        policyUrl: urlYup.required(),
        cookieUrl: urlYup.required(),
        issuer: yup
          .object()
          .shape<IssuerShape>({
            name: yup.string().required(),
            authorization_endpoint: urlYup.required(),
            token_endpoint: urlYup.required(),
            client_id: yup.string().required(),
            requested_scopes: yup.string().required(),
            redirect_uri: urlYup.required(),
            end_session_endpoint: urlYup.required(),
            redirect_logout_uri: urlYup.required(),
            pkce: yup.boolean().required(),
          })
          .required(),
        images: yup.object().shape<ImageShape>({
          default: urlYup.required(),
          tab1: urlYup.required(),
          tab2: urlYup.required(),
          tab3: urlYup.required(),
          tab4: urlYup.required(),
          url: urlYup.required(),
        }),
        icons: yup.object().shape<IconShape>({
          error: urlYup.required(),
          wait: urlYup.required(),
          download: urlYup.required(),
          'request-wait': urlYup.required(),
        }),
        htmlBlocks: yup.object().noUnknown(false),
      })
      .required(),
  });

const useWellKnown = () => {
  const [wellKnown, setWellKnown] = useState<WellKnown>(
    WellKnownProfile.getData()
  );

  const loading = isEmpty(WellKnownProfile.getData());

  const url = useMemo(
    () => process.env.REACT_APP_WELL_KNOWN || '/.well-known',
    []
  );

  async function createWellKnown(data: WellKnown) {
    try {
      await wellknownSchema.validate(data);
      WellKnownProfile.setData(data);
      setWellKnown(data);
    } catch (err) {
      throw err;
    }
  }

  useEffect(() => {
    async function fetch() {
      try {
        const { data } = await axios.create().get<WellKnown>(url);
        const processed = processData(data);
        createWellKnown(processed);
      } catch (err) {
        console.error('Well known', err);
      }
    }
    if (!WellKnownProfile.getData()) {
      fetch();
    } else {
      setWellKnown(WellKnownProfile.getData());
    }
  }, [url]);

  return { wellKnown, loading };
};

export const WellKnownProfile = (function () {
  let data: WellKnown;

  const getData = function () {
    return data;
  };

  const setData = function (input: WellKnown) {
    data = input;
  };

  return {
    getData,
    setData,
  };
})();

function processData(data: WellKnown): WellKnown {
  const { images, ...metadata } = data.metadata;
  const newImages = (Object.keys(images) as ImagesKeys).reduce(
    (imgs, imgKey) => {
      const newUrl = `${images[imgKey]}?rnd=${nanoid()}`;
      return { ...imgs, ...{ [imgKey]: newUrl } };
    },
    {} as Metadata['images']
  );

  const metadataNew: Metadata = {
    ...metadata,
    images: newImages,
    issuer: {
      ...metadata.issuer,
      pkce: true,
    },
  };
  const dataNew: WellKnown = { ...data, metadata: { ...metadataNew } };
  return dataNew;
}
export default useWellKnown;
