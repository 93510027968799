import React, { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import Loadable from '../core/Loadable';
import SimpleRouteLayout from '../layouts/SimpleRouteLayout';
import VoucherSuccess from '../pages/TicketSuccess';
import { PageNotFountBoundary, ErrorBoundary } from './Routes';
import { PUBLIC_ROUTE } from './routesUtils';

const Home = Loadable(lazy(() => import('../pages/Home')));
const SignUp = Loadable(lazy(() => import('../pages/SignUp')));
const SignUpSuccess = Loadable(lazy(() => import('../pages/SignUpSuccess')));
const VoucherCheckout = Loadable(lazy(() => import('../pages/TicketCheckout')));

const PublicRouters: React.FC = () => {
  return useRoutes([routes]);
};
export default PublicRouters;

const routes: RouteObject = {
  element: <SimpleRouteLayout />,
  children: [
    {
      path: PUBLIC_ROUTE.HOME,
      element: <Home />,
    },
    {
      path: PUBLIC_ROUTE.SIGN_UP,
      element: <SignUp />,
    },
    {
      path: PUBLIC_ROUTE.SIGN_UP_SUCCESS,
      element: <SignUpSuccess />,
    },
    {
      path: `${PUBLIC_ROUTE.TICKET_CHECKOUT}/:voucher`,
      element: <VoucherCheckout />,
    },
    {
      path: PUBLIC_ROUTE.TICKET_CHECKOUT,
      element: <VoucherCheckout />,
    },
    {
      path: PUBLIC_ROUTE.TICKET_SUCCESS,
      element: <VoucherSuccess />,
    },
    {
      path: '/*',
      element: <PageNotFountBoundary />,
    },
  ],
  errorElement: <ErrorBoundary />,
};
