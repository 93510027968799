import { AccountCircle } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Container,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  MenuItemProps,
  Stack,
  StackProps,
  SxProps,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect, useState } from 'react';
import logo from '../assets/images/logo.png';
import colors from '../assets/scss/_themes-vars.module.scss';
import { NAVBAR_HEIGHT } from '../constants';
export type NavBarProps = {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
  menuItems?: {
    sx?: SxProps;
    elements: React.ReactNode[];
  };
  onLogoClick?: () => void;
};

const HorizontalMenuSx: SxProps = {
  display: {
    xs: 'none',
    sm: 'none',
    md: 'block',
    lg: 'block',
    xl: 'block',
  },
};

const VerticalMenuIconSx: SxProps = { display: { md: 'none' } };

const VerticalMenuSx: Record<string, SxProps> = {
  Drawer: { top: NAVBAR_HEIGHT, zIndex: 98 },
  Paper: {
    top: NAVBAR_HEIGHT,
    backgroundColor: `${colors.primaryMain}`,
  },
};

const NavBarSx: SxProps = { minHeight: NAVBAR_HEIGHT, zIndex: 99 };

const NavBar: React.FC<NavBarProps> = props => {
  const { left, center, right, onLogoClick, menuItems } = props;

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  return (
    <Box>
      <AppBar position="fixed" sx={NavBarSx}>
        <Container fixed>
          <Toolbar style={{ padding: 0 }}>
            {menuItems && (
              <Box sx={VerticalMenuIconSx}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => setOpenMenu(prev => !prev)}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            )}
            <Box onClick={() => setOpenMenu(false)}>{left}</Box>
            <IconButton
              onClick={() => {
                setOpenMenu(false);
                onLogoClick && onLogoClick();
              }}
            >
              <img src={logo} alt="logo" height={50} />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} onClick={() => setOpenMenu(false)}>
              {menuItems && (
                <Box sx={HorizontalMenuSx}>
                  <MenuItems items={menuItems.elements} direction={'row'} />
                </Box>
              )}
              <>{center}</>
            </Box>
            <Box onClick={() => setOpenMenu(false)}>{right}</Box>
          </Toolbar>
        </Container>
      </AppBar>
      {menuItems && (
        <Drawer
          anchor={'top'}
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          sx={VerticalMenuSx.Drawer}
          PaperProps={{ sx: VerticalMenuSx.Paper }}
          BackdropProps={{
            sx: { top: NAVBAR_HEIGHT },
          }}
          onClick={() => setOpenMenu(false)}
        >
          <Container fixed sx={{ px: 2 }}>
            <MenuItems items={menuItems.elements} direction={'column'} />
          </Container>
        </Drawer>
      )}
    </Box>
  );
};

export default NavBar;

export type NoAccoutCircleProps = {
  items: {
    label: string;
    onClick: MenuItemProps['onClick'];
    divider?: boolean;
  }[];
};
export const NoAccoutCircle: React.FC<NoAccoutCircleProps> = props => {
  const { items } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => handleClose();
  }, []);

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ top: 40, zIndex: 100 }}
      >
        {items.map(({ label, onClick, divider }) => (
          <div key={label}>
            {divider && <Divider />}
            <MenuItem
              onClick={event => {
                handleClose();
                onClick && onClick(event);
              }}
            >
              {label}
            </MenuItem>
          </div>
        ))}
      </Menu>
    </div>
  );
};

type MenuItemsProps = {
  items: React.ReactNode[];
  direction: StackProps['direction'];
};

const MenuItems: React.FC<MenuItemsProps> = props => {
  const { direction, items } = props;
  return (
    <Stack
      direction={direction}
      spacing={3}
      sx={{
        px: direction === 'row' ? 3 : 0,
        py: direction === 'column' ? 2 : 0,
      }}
    >
      {items?.map((item, index) => (
        <Box key={index}>{item}</Box>
      ))}
    </Stack>
  );
};

/*

*/
