import { NOTIFICATION_TYPE, Store } from 'react-notifications-component';
export type Message = {
  message: string;
  title?: string;
};
export function dispatchMessage(
  { message, title }: Message,
  type: NOTIFICATION_TYPE = 'success'
) {
  Store.addNotification({
    title: title,
    message: message || 'Info',
    type,
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 10000,
      onScreen: true,
    },
  });
}

export function dispatchErrorMessage(
  { message, title }: Message,
  err: unknown
) {
  console.error('dispatchErrorMessage says', err);
  Store.addNotification({
    title: title,
    message: message || 'Errore',
    type: 'danger',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 10000,
      onScreen: true,
    },
  });
}
