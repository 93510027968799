import { useAuthentication } from '@iad-os/react-ghost-auth';
import { Menu, MenuItem, Divider } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../components/Avatar';
import { useAppStore } from '../../libs/contexts/AppStore';
import { protectedNavigate } from '../../routes/routesUtils';

const AccountAvatar = () => {
  const { logout } = useAuthentication();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const { state } = useAppStore();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    handleClose();
    navigate(protectedNavigate('PROFILE'));
  };

  return (
    <div>
      <Avatar
        size="large"
        onClick={handleMenu}
        name={
          state.me?.firstName && state.me?.lastName
            ? `${state.me?.firstName} ${state.me?.lastName}`
            : ''
        }
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ top: 40 }}
      >
        <MenuItem onClick={handleProfile}>Profilo</MenuItem>
        <Divider />
        <MenuItem onClick={() => logout()}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default AccountAvatar;
