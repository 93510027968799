import { useAuthentication } from '@iad-os/react-ghost-auth';
import { Box, Typography, Link } from '@mui/material';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useWellKnown from '../../hooks/useWellKnown';
import { NavBarProps, NoAccoutCircle } from '../../libs/components/NavBar';
import { useAppStore } from '../../libs/contexts/AppStore';
import AppLayout from '../../libs/layouts/AppLayout';
import { protectedNavigate, publicNavigate } from '../../routes/routesUtils';
import AccountAvatar from './AccountAvatar';

const MainRouteLayout: React.FC = () => {
  const { isAuthenticated, login } = useAuthentication();
  const navigate = useNavigate();
  const auth = isAuthenticated();
  const { wellKnown } = useWellKnown();

  const { state } = useAppStore();

  const navBarProps: NavBarProps = {
    onLogoClick: () => navigate(publicNavigate('HOME')),
    menuItems: {
      elements: [
        <Typography
          component={Link}
          color={'secondary'}
          onClick={() => navigate(publicNavigate('HOME'))}
          href="#"
        >
          Home
        </Typography>,
        <Typography
          component={Link}
          color={'secondary'}
          onClick={() =>
            auth
              ? navigate(protectedNavigate('TICKET_CHECKOUT'))
              : navigate(publicNavigate('TICKET_CHECKOUT'))
          }
          href="#"
        >
          Richiedi biglietto
        </Typography>,
        <Typography
          component={Link}
          color={'secondary'}
          onClick={() => navigate(protectedNavigate('TICKETS'))}
          href="#"
        >
          I miei biglietti
        </Typography>,
      ],
    },
    right: (
      <>
        {auth ? (
          <AccountAvatar />
        ) : (
          <NoAccoutCircle
            items={[
              {
                label: 'Registrati',
                onClick: () => navigate(publicNavigate('SIGN_UP')),
              },
              { label: 'Accedi', onClick: () => login() },
            ]}
          />
        )}
      </>
    ),
  };

  return (
    <Box>
      <AppLayout
        title={state.title}
        NavBarProps={navBarProps}
        defaultImgUrl={wellKnown?.metadata?.images?.default}
        footerInfos={{
          policyUrl: wellKnown.metadata.policyUrl,
          cookieUrl: wellKnown.metadata.cookieUrl,
        }}
      >
        <Outlet />
      </AppLayout>
    </Box>
  );
};

export default MainRouteLayout;
