import { Box, Link, Stack, SxProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import useVersion from '../hooks/useVersion';

export type FooterInfos = { policyUrl: string; cookieUrl: string };
type FooterBarProps = { infos: FooterInfos };

const FooterBar: React.FC<FooterBarProps> = props => {
  const { infos } = props;
  const theme = useTheme();

  const version = useVersion();

  const FooterSx: SxProps = {
    marginTop: 'auto',
    backgroundColor: '#1f1f1f',
    alignItems: 'center',
    display: 'flex',
    height: 'auto',
    width: '100%',
    color: 'white',
  };
  return (
    <Box sx={FooterSx}>
      <Stack width={'100%'}>
        <Box
          sx={{
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
            gap: 3,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0,
            },
          }}
        >
          <Link
            href={infos.policyUrl}
            target="_blank"
            color="#FFFF"
            variant="caption"
          >
            Policy Privacy
          </Link>
          <Link
            href={infos.cookieUrl}
            target="_blank"
            color="#FFFF"
            variant="caption"
          >
            Cookie Policy
          </Link>
          {version && (
            <Typography variant="caption">{`Versione ${version}`}</Typography>
          )}
        </Box>
        <Typography variant="caption" textAlign={'center'}>
          Tufano Teresa S.r.l. Cap. Soc. i.v. € 312.000,00 - Sede legale in Via
          Principe di Piemonte 199, cap. 80026 Casoria (NA) - C.F. 05834470634 -
          P.I. 01465221214, iscritta alla C.C.I.A.A. Napoli, REA 459938.
        </Typography>
      </Stack>
    </Box>
  );
};

export default FooterBar;
