import { Stack, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { TitleInfo } from '../contexts/AppStore';
import useDeviceDetect from '../hooks/useDeviceDetect';

export type TitleProps = {
  title?: TitleInfo;
  defaultImgUrl?: string;
};

const Title: React.FC<TitleProps> = props => {
  const theme = useTheme();

  const { device } = useDeviceDetect();

  const { title, defaultImgUrl } = props;

  const image = useMemo(
    () => `${title?.imageUrl ?? defaultImgUrl}`.replace('{{device}}', device),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [device, title, defaultImgUrl]
  );

  return (
    <Stack
      spacing={2}
      sx={{
        backgroundImage: `url(${image})`,
        height: 600,
        backgroundPosition: 'bottom center',
        backgroundSize: 'cover',

        [theme.breakpoints.down('lg')]: {
          height: 400,
        },
        [theme.breakpoints.down('sm')]: {
          height: 250,
        },
      }}
      textAlign="center"
      justifyContent={'center'}
    ></Stack>
  );
};

export default Title;
