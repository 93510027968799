import { Box, Container, Stack } from '@mui/material';
import React from 'react';
import NavBar, { NavBarProps } from '../components/NavBar';
import FooterBar, { FooterInfos } from '../components/FooterBar';
import Title from '../components/Title';
import { NAVBAR_HEIGHT } from '../constants';
import banner from '../assets/images/banner.jpg';
import { TitleInfo } from '../contexts/AppStore';

export type AppLayoutProps = {
  title?: TitleInfo;
  NavBarProps?: NavBarProps;
  infoNode?: React.ReactNode;
  defaultImgUrl?: string;
  footerInfos: FooterInfos;
  children?: React.ReactNode;
};

const AppLayout: React.FC<AppLayoutProps> = props => {
  const { children, NavBarProps, title, infoNode, defaultImgUrl, footerInfos } =
    props;

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <NavBar {...NavBarProps} />
      <Box marginTop={`${NAVBAR_HEIGHT}px`}>
        <Title title={title} defaultImgUrl={defaultImgUrl ?? banner} />
        <Container fixed sx={{ py: 2 }}>
          {!!infoNode && (
            <Stack sx={{ width: '100%', pb: 4 }} spacing={2}>
              {infoNode}
            </Stack>
          )}
          {children}
        </Container>
      </Box>
      <FooterBar infos={footerInfos} />
    </Box>
  );
};

export default AppLayout;
