import React, { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router';
import Loadable from '../core/Loadable';
import ProtectedRouteLayout from '../layouts/ProtectedRouteLayout';
import { ErrorBoundary, PageNotFountBoundary } from './Routes';
import { PROTECTED_ROUTE } from './routesUtils';

const Profile = Loadable(lazy(() => import('../pages/Profile')));

const Ticket = Loadable(lazy(() => import('../pages/Ticket')));
const TicketsList = Loadable(lazy(() => import('../pages/TicketsDowload')));
const VoucherCheckout = Loadable(lazy(() => import('../pages/TicketCheckout')));
const VoucherSuccess = Loadable(lazy(() => import('../pages/TicketSuccess')));

const router: RouteObject = {
  element: <ProtectedRouteLayout />,
  children: [
    {
      path: PROTECTED_ROUTE.PROFILE,
      element: <Profile />,
    },
    {
      path: PROTECTED_ROUTE.TICKETS,
      element: <TicketsList />,
    },
    {
      path: `${PROTECTED_ROUTE.TICKETS}/:id`,
      element: <Ticket />,
    },
    {
      path: `${PROTECTED_ROUTE.TICKET_CHECKOUT}/:voucher`,
      element: <VoucherCheckout />,
    },
    {
      path: PROTECTED_ROUTE.TICKET_CHECKOUT,
      element: <VoucherCheckout />,
    },
    {
      path: PROTECTED_ROUTE.TICKET_SUCCESS,
      element: <VoucherSuccess />,
    },
    {
      path: '/*',
      element: <PageNotFountBoundary />,
    },
  ],
  errorElement: <ErrorBoundary />,
};

const ProtectedRouters: React.FC = () => {
  return useRoutes([router]);
};

export default ProtectedRouters;
