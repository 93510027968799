import React, { Suspense } from 'react';
import PageLoader from '../components/PageLoader';

const Loadable =
  (Component: React.LazyExoticComponent<() => JSX.Element>) => (props: any) =>
    (
      <Suspense fallback={<PageLoader />}>
        <Component {...props} />
      </Suspense>
    );

export default Loadable;
