import {
  Avatar as AvatarMUI,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import React, { useMemo } from 'react';

type AvatarProps = {
  name: string;
} & Pick<IconButtonProps, 'onClick' | 'sx' | 'size'>;

const Avatar: React.FC<AvatarProps> = props => {
  const { name, ...iprops } = props;

  const url = useMemo(
    () => `https://eu.ui-avatars.com/api/?name=${name.replace(' ', '+')}`,
    [name]
  );
  return (
    <IconButton {...iprops}>
      <AvatarMUI alt="avatar" src={url} />
    </IconButton>
  );
};

export default Avatar;
