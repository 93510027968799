export const ROUTES = {
  PUBLIC: '/public',
  PROTECTED: '/protected',
  COMMON: '/common',
} as const;

export const PUBLIC_ROUTE = {
  HOME: ``,
  SIGN_UP: `/sign-up`,
  SIGN_UP_SUCCESS: `/sign-up/success`,
  TICKET_CHECKOUT: `/tickets/checkout`,
  TICKET_SUCCESS: `/tickets/success`,
} as const;

export const PROTECTED_ROUTE = {
  TICKETS: `/tickets`,
  TICKET_CHECKOUT: `/tickets/checkout`,
  TICKET_SUCCESS: `/tickets/success`,
  PROFILE: `/profiles`,
  ON_BOARDING: `/onboarding`,
} as const;
export const COMMON_ROUTE = {
  TICKET_CHECKOUT: `/tickets/checkout`,
} as const;

export function publicNavigate(route: keyof typeof PUBLIC_ROUTE) {
  return `${ROUTES.PUBLIC}${PUBLIC_ROUTE[route]}`;
}
export function protectedNavigate(route: keyof typeof PROTECTED_ROUTE) {
  return `${ROUTES.PROTECTED}${PROTECTED_ROUTE[route]}`;
}
export function commonNavigate(route: keyof typeof COMMON_ROUTE) {
  return `${ROUTES.COMMON}${COMMON_ROUTE[route]}`;
}
