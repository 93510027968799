import { AxiosInstance } from 'axios';
import { useEffect, useMemo, useState } from 'react';

export type InterceptorProps = {
  axiosInstance: AxiosInstance;
  onError?: (err: Error) => void;
  timeout?: number;
};

const useInterceptor = (props: InterceptorProps) => {
  const { axiosInstance, onError, timeout } = props;
  const [numberOfCalls, setNumberOfCalls] = useState<number>(0);
  const loading = useMemo(() => numberOfCalls > 0, [numberOfCalls]);

  useEffect(() => {
    if (timeout) {
      axiosInstance.defaults.timeout = timeout;
    }
    axiosInstance.interceptors.request.use(
      config => {
        setNumberOfCalls(prev => prev + 1);
        return config;
      },
      error => {
        setNumberOfCalls(prev => prev - 1);
        return Promise.reject(error);
      }
    );
    axiosInstance.interceptors.response.use(
      response => {
        setNumberOfCalls(prev => prev - 1);
        return response;
      },
      async error => {
        setNumberOfCalls(prev => prev - 1);
        onError && onError(error);
        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    numberOfCalls,
  };
};

export default useInterceptor;
