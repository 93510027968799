import React, { useContext, useReducer } from 'react';

export type Me = {
  _id: string;
  subject: string;
  firstName: string;
  lastName: string;
  email: string;
  fiscalCode: string;
  phoneNumber: string;
  gender: 'M' | 'F';
  consents: {
    _id: string;
    value: boolean;
    createdAt?: Date;
    updatedAt?: Date;
  }[];
  createdAt: Date;
  updatedAt: Date;
};

export type TitleInfo = {
  text?: string;
  imageUrl?: string;
};

type AppStoreState = {
  title?: TitleInfo;
  me?: Me;
  boardingCompleted: boolean;
};

const initialState: AppStoreState = {
  boardingCompleted: true,
};

const AppStore = React.createContext({ state: initialState } as {
  state: AppStoreState;
  dispatch: <T extends TypeAction>(value: T) => void;
});

export function useAppStore() {
  return useContext(AppStore);
}

export type TypeAction = ResetAction | UpdateAction<keyof AppStoreState>;

export type ResetAction = {
  type: 'reset';
};

export type UpdateAction<K extends keyof AppStoreState> = {
  type: 'update';
  field: {
    key: K;
    value: AppStoreState[K];
  };
};

function reducer(state: AppStoreState, action: TypeAction) {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'update':
      return { ...state, [action.field.key]: action.field.value };
  }
}

const AppStoreProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppStore.Provider
      value={{
        state,
        dispatch: dispatch,
      }}
    >
      {props.children}
    </AppStore.Provider>
  );
};

export default AppStoreProvider;
