import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  Theme,
  ThemeProvider as ThemeProviderMUI,
} from '@mui/material/styles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import themePalette from './palette';
import colors from '../assets/scss/_themes-vars.module.scss';

type AppThemeProps = { toggleColorMode: () => void };
declare module '@mui/material/styles' {
  interface TypographyVariants {
    bold: React.CSSProperties;
    'h5-bold': React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bold?: React.CSSProperties;
    'h5-bold'?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bold: true;
    'h5-bold': true;
  }
}
const AppTheme = React.createContext({} as AppThemeProps);

export function useAppTheme() {
  return useContext(AppTheme);
}

const GHOST_THEME_MODE = 'ghost_theme_mode';

export type AppThemeOptions = {
  colors: {
    readonly [key: string]: string;
  };
  heading: string;
  paper: string;
  backgroundDefault: string;
  background: string;
  darkTextPrimary: string;
  darkTextSecondary: string;
  textDark: string;
  menuSelected: string;
  menuSelectedBack: string;
  divider: string;
  customization?: {
    mode: Theme['palette']['mode'];
  };
};

const AppThemeProvider: React.FC<{ children: React.ReactNode }> = props => {
  const [mode, setMode] = useState<Theme['palette']['mode']>(
    (localStorage.getItem(GHOST_THEME_MODE) as Theme['palette']['mode']) ||
      'light'
  );

  const color = colors;

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  useEffect(() => {
    mode && localStorage.setItem(GHOST_THEME_MODE, mode);
  }, [mode]);

  const themeOption: AppThemeOptions = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    customization: {
      mode,
    },
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: themePalette(themeOption),
        typography: {
          fontFamily: 'Poppins',
          bold: {
            fontWeight: 800,
          },
          'h5-bold': {
            fontSize: ' 1.5rem',
            lineHeight: '1.334',
            marginBottom: ' 0.35em',
            fontWeight: 800,
            fontFamily: 'Poppins',
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode]
  );

  return (
    <AppTheme.Provider value={colorMode}>
      <ThemeProviderMUI theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProviderMUI>
    </AppTheme.Provider>
  );
};

export default AppThemeProvider;
