import React, { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router';
import { Navigate, useRouteError } from 'react-router-dom';
import Loadable from '../core/Loadable';
import MainRouteLayout from '../layouts/MainRouteLayout';
import ProtectedRouters from './ProtectedRoutes';
import PublicRouters from './PublicRoutes';
import CommonRouters from './CommonRoutes';
import { ROUTES } from './routesUtils';
import { Alert, AlertTitle } from '@mui/material';
import { RequireAuth } from '@iad-os/react-ghost-auth';
const OnBoarding = Loadable(lazy(() => import('../pages/OnBoarding')));

const routes: RouteObject = {
  element: <MainRouteLayout />,
  children: [
    {
      path: `${ROUTES.PROTECTED}/*`,
      element: <ProtectedRouters />,
    },
    {
      path: `${ROUTES.PUBLIC}/*`,
      element: <PublicRouters />,
    },
    {
      path: `${ROUTES.COMMON}/*`,
      element: <CommonRouters />,
    },
    {
      path: '/onboarding',
      element: (
        <RequireAuth authRequired={true}>
          <OnBoarding />
        </RequireAuth>
      ),
    },
    {
      path: '/',
      element: <Navigate to="/public" />,
    },
    {
      path: '/*',
      element: <PageNotFountBoundary />,
    },
  ],
  errorElement: <ErrorBoundary />,
};

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <pre>{JSON.stringify(error, null, 4)}</pre>
    </Alert>
  );
}

export function PageNotFountBoundary() {
  return (
    <Alert severity="warning">
      <AlertTitle>OOPS... Pagina non trovata!</AlertTitle>
    </Alert>
  );
}

const Routes: React.FC = () => {
  return useRoutes([routes]);
};

export default Routes;
